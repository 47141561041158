.reportageSellerPriceInput{
    all: unset;
    padding: 12px 0;
}


/* Pagination */

.sitePaginationMainBox {
    display: flex;
    gap: 10px;
    flex-direction: row-reverse;
}

.sitePaginationCard {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #EFEFF1;
    display: grid;
    place-items: center;
    cursor: pointer;
    transition: all .4s ease;
}

.sitePaginationCard:hover {
    border-color: #b2182c;
}

.sitePaginationCard.active {
    border-color: #b2182c;
    cursor: default;
}

/* Pagination (END) */